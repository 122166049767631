import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import toast, { Toaster } from 'react-hot-toast';
import {addDays} from 'date-fns';
import ru from 'date-fns/locale/ru';
import CloseSidebar from "../../Main/CloseSidebar";
import axios from 'axios';
import "react-datepicker/dist/react-datepicker.css"

function Write(props) {
    CloseSidebar();

    const API_SCRIPT = process.env.REACT_APP_API_SCRIPT;

    // прелоадер при загрузке страницы
    const [pageLoaded, setPageLoaded] = useState(true);

    // прелоадер при получении времени
    const [isLoaded, setIsLoaded] = useState(false);

    // массив времени
    const [items, setItems] = useState([]);

    // выбранная дата
    const [selectDate, setDate] = useState(new Date());

    // показ модали
    const [isModal, setModal] = useState(false);

    // массив контента для модали
    const [isModalContent, setModalContent] = useState([]);

    // режим получения графика, true - мобила, false - пк
    const [isMobile, setMobile] = useState('');

    // даты в календаре
    const [includeDates, setIncludeDate] = useState([]);

    // закрывашка и очистка контента модали
    const onClose = () => {
        setModal(false);
        setModalContent([]);
    }

    const [instructors, setInstructors] = useState([]);
    const [instructor, setInstructor] = useState([]);
    const [changeClass, setChangeClass] = useState(false);

    // режим отображения расписания
    const [viewMode, setViewMode] = useState('standart');

    // кнопка для пикера
    const DatePickerButton = React.forwardRef(({value, onClick}, ref) => (
        <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick} ref={ref}>
            <path d="M1.60742 9.97154H22.402" stroke="#A3B8CE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17.1815 14.5279H17.1923" stroke="#A3B8CE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.0057 14.5279H12.0165" stroke="#A3B8CE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.8182 14.5279H6.82901" stroke="#A3B8CE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17.1815 19.0621H17.1923" stroke="#A3B8CE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.0057 19.0621H12.0165" stroke="#A3B8CE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.8182 19.0621H6.82901" stroke="#A3B8CE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M16.7181 1.33325V5.17249" stroke="#A3B8CE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.29235 1.33325V5.17249" stroke="#A3B8CE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M16.9446 3.17566H7.06612C3.63999 3.17566 1.5 5.08425 1.5 8.59252V19.1504C1.5 22.7139 3.63999 24.6666 7.06612 24.6666H16.9338C20.3708 24.6666 22.5 22.747 22.5 19.2387V8.59252C22.5108 5.08425 20.3816 3.17566 16.9446 3.17566Z" stroke="#A3B8CE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    ));

    // месяцы, дни, дни сокращенно
    const months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
    const days = ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'];
    const daysShort = ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'];

    // формирование даты для титла
    const selectDateTitle = selectDate.getDate() + ' ' + months[selectDate.getMonth()] + ' (' + days[selectDate.getDay()] + ')';

    // получение цифорок даты
    const selectDateArr = [
        selectDate.getDate(),
        selectDate.getMonth() + 1,
        selectDate.getFullYear()
    ];

    const config = {
        headers : {
            'Content-Type' : 'application/x-www-form-urlencoded'
        }
    };

    // загрузка списка инструкторов
    const loadInstructor = () => {
        const client = {
            path: 'client/instructors',
            school_id: props.auth.school_id,
            client_id: props.auth.client_id
        };

        axios.post(API_SCRIPT, client, config).then(response => {
            response = response.data;
            if (response.status === 'done') {
                setInstructors([]);
                if (response.instructors !== false) {
                    setInstructors(response.instructors);
                    setInstructor(response.instructors[0]);
                }
                setPageLoaded(false);
                //setDate(new Date())
            }
        })

    };

    // получение доступных для записи дат
    const loadIncludeDates = () => {
        const data = {
            path: 'client/practice/dates',
            school_id: props.auth.school_id,
            instructor_id: instructor.id,
        };

        axios.post(API_SCRIPT, data, config).then(response => {
            response = response.data;

            if (response.status === 'done') {
                setIncludeDate([]);
                if (response.dates !== false) {
                    let includeDatesNew = response.dates;
                    includeDatesNew.forEach((item, index) => {
                        includeDatesNew[index] = new Date(item);
                    });

                    setIncludeDate(includeDatesNew);
                }
                setPageLoaded(false);
            }
        })
    }

    // обновлялка инструкторов и режима отображения
    useState(() => {
        document.title = 'Вождение | Права Мобил';
        loadInstructor();
        const width = window.innerWidth;
        if (width >= 1140) {
            setMobile(false);
        } else {
            setMobile(true);
        }
    }, []);

    // обновлялка времени если мобильный или получение расписания если пк
    useEffect(() => {
        if (instructor && instructor.id) {
            setIsLoaded(true);
            setItems([]);

            loadIncludeDates();

            if (isMobile) {
                if (viewMode === 'standart') {
                    const times = {
                        path: 'client/practice/times',
                        school_id: props.auth.school_id,
                        instructor_id: instructor.id,
                        date: selectDateArr.join('.')
                    };

                    axios.post(API_SCRIPT, times, config).then(response => {
                        response = response.data;
                        setIsLoaded(false);
                        if (response.status === 'done') {
                            setItems(response.times);
                        }
                    })
                } else {
                    const times = {
                        path: 'client/practice/full',
                        school_id: props.auth.school_id,
                        instructor_id: instructor.id,
                        date: selectDateArr.join('.')
                    };

                    axios.post(API_SCRIPT, times, config).then(response => {
                        response = response.data;
                        setIsLoaded(false);
                        if (response.status === 'done') {
                            setItems(response.dates);
                        }
                    })
                }
            } else {
                const times = {
                    path: 'client/practice/schedule',
                    school_id: props.auth.school_id,
                    instructor_id: instructor.id,
                    client_id: props.auth.client_id
                };

                axios.post(API_SCRIPT, times, config).then(response => {
                    response = response.data;
                    setIsLoaded(false);
                    if (response.status === 'done') {
                        setItems(response.schedules);
                    }
                })
            }
        }
    }, [selectDate, instructor]);

    window.addEventListener('resize', function(event) {
        const width = window.innerWidth;
        if ((isMobile === true && width === 1140) || (isMobile === false && width < 1140)) {
            window.location.reload()
        }
    }, true);

    // модальное окно
    const Modal = ({visible = false, content}) => {
        if (!visible) return null;

        let timeTitle = content.time_title.split(' - ');
        timeTitle = timeTitle[0];

        return (
            <div className="practice-write-modal position-fixed">
                <div className="practice-write-modal-dialog text-center">
                    <div className="practice-write-modal-header">
                        <div className="practice-write-modal-instructor-title">Инструктор</div>
                        <div className="practice-write-modal-modal-title">{instructor.lastname} {instructor.name}</div>
                        <div className="practice-write-modal-datetime">{content.date_title} {timeTitle}</div>
                    </div>
                    <div className="practice-write-modal-confirm">
                        <div className="practice-write-modal-confirm-title">Записаться?</div>
                        <div className="practice-write-modal-confirm-btn-block d-flex">
                            <div className="practice-write-modal-confirm-btn">
                                <div className="confirm-btn-no" onClick={onClose}>Нет</div>
                            </div>
                            <div className="practice-write-modal-confirm-btn">
                                <div className="confirm-btn-yes" onClick={() => writePractice()}>Да</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    // сохранение выбранного контента и запуск модали
    const timeClick = (item, index) => {
        if (changeClass) return null;
        if (!isMobile && item.status !== 0 && item.status !== 8) return null;

        let dateTitle = selectDate.getDate() + ' ' + months[selectDate.getMonth()] + ' (' + daysShort[selectDate.getDay()] + ')';
        let date = selectDateArr.join('.');
        let week = false,
            day = false,
            dayId = false,
            timeId = false;

        if (!isMobile || viewMode === 'full') {
            let splitDate = item.date.split('.');
            let newDate = new Date(splitDate[2], splitDate[1] - 1, splitDate[0]);
            dateTitle = newDate.getDate() + ' ' + months[newDate.getMonth()] + ' (' + daysShort[newDate.getDay()] + ')';
            date = item.date;
            week = item.week;
            day = item.day;
            dayId = item.dayId;
            timeId = item.timeId;
        }

        const content = {
            date_title: dateTitle,
            date: date,
            time_id: Number(item.id),
            time_title: item.title,
            item: index,
            week: week,
            day: day,
            dayId: dayId,
            timeId: timeId
        };

        // сохранение данных о дате и времени занятия
        setModalContent(content);

        // запуск модальки
        setModal(true);
    }

    // запись на занятие
    const writePractice = () => {
        const write = {
            path: 'client/practice/write',
            client_id: props.auth.client_id,
            school_id: props.auth.school_id,
            instructor_id: instructor.id,
            date: isModalContent.date,
            time_id: isModalContent.time_id,
            time_title: isModalContent.time_title
        };

        axios.post(API_SCRIPT, write, config).then(response => {
            response = response.data;
            let timesItems = items;
            if (response.status === 'done') {
                if (isMobile) {
                    if (viewMode === 'full') {
                        timesItems[isModalContent.dayId].times.splice(isModalContent.timeId, 1);
                    } else {
                        timesItems.splice(isModalContent.item, 1);
                    }
                } else {
                    delete timesItems[isModalContent.week][isModalContent.day].times[isModalContent.time_id];
                }

                toast.success('Успешно', {
                    duration: 5000,
                    position: 'bottom-center'
                });

                setItems(timesItems);
                props.client.practice--;
            } else {
                toast.error(response.error, {
                    duration: 5000,
                    position: 'bottom-center'
                });
            }

            setModal(false);
        })
    }

    // формирование объекта standart из элементов времени
    const itemsElements = items.map((item, index) => {
        if (!instructor) return null;

        return (
            <div className="practice-write-item" key={index} onClick={() => timeClick(item, index)}>{item.title}</div>
        )
    });

    // формирование full объекта standart из элементов времени
    const itemsFullElements = items.map((day, dayIndex) => {
        if (viewMode === 'standart') return null;
        if (!instructor) return null;
        if (day.times && day.times.length === 0) return null;

        return (
            <div className="practice-write-full-item" key={dayIndex}>
                <div className="practice-write-body-title">{day.title}</div>
                <div className="d-flex flex-wrap">
                    {day.times.map((time, timeIndex) => {
                        const item = {
                            'id': time.id,
                            'date': day.date,
                            'title': time.title,
                            'dayId': dayIndex,
                            'timeId': timeIndex
                        }

                        return (
                            <div className="practice-write-item" key={timeIndex} onClick={() => timeClick(item, timeIndex)}>{time.title}</div>
                        )
                    })}
                </div>
            </div>
        )
    });

    // формирование ссылок на вкладки
    const tabsLink = items.map((item, index) => {
        if (!instructor) return null;
        if (isMobile) return null;
        return (
            <div className={'tab-item' + (index === 0 ? ' active' : '')} data-tabs="main" data-id={'tab-' + index} key={index}>{item[1].date} - {item[7].date}</div>
        )
    });

    // формирование вкладок
    const tabsBlock = items.map((week, weekIndex) => {
        if (!instructor) return null;
        if (isMobile) return null;

        let style = {};

        if (weekIndex !== 0) {
            style = {
                display: 'none'
            }
        }

        return (
            <div className="tab-block" data-tab="main" tab-id={'tab-' + weekIndex} style={style} key={weekIndex}>
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between">
                            {Object.keys(week).map((dayKey, dayIndex) => {
                                return (
                                    <div className="schedule-day text-center" key={dayIndex}>
                                        <div className="schedule-day-header text-bold">{week[dayKey].day}<br/>{week[dayKey].date}</div>
                                        {Object.keys(week[dayKey].times).map((timeKey, timeIndex) => {
                                            const item = {
                                                'id': week[dayKey].times[timeKey].id,
                                                'date': week[dayKey].date,
                                                'title': week[dayKey].times[timeKey].title,
                                                'status': week[dayKey].times[timeKey].status,
                                                'week': weekIndex,
                                                'day': parseInt(dayKey)
                                            }

                                            return (
                                                <div className="btn-group" key={timeIndex} onClick={() => timeClick(item, timeIndex)}>
                                                    <div className="schedule-day-time bg-default" className={'schedule-day-time ' + week[dayKey].times[timeKey].color}>
                                                        <div className="schedule-day-time-period">{week[dayKey].times[timeKey].title}</div>
                                                        <div className="schedule-day-time-status">{week[dayKey].times[timeKey].statusText}</div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        )
    });

    // показать/скрыть инструкторов
    const showInstructor = () => {
        if (instructors.length <= 1) return null;
        loadInstructor();
        setChangeClass(!changeClass);
    }

    // переназначение инструкторов
    const changeInstructor = (instructor) => {
        // смена видимости списка инструкторов
        setChangeClass(!changeClass);

        // установка инструктора
        setInstructor(instructor);

        // установка сегодняшней даты и обновление свободного времени
        setDate(new Date());
    }

    // формирование объекта из инструкторов
    const instructorsElement = instructors.map((item, index) => {
        //if (item.id === instructor.id) return null;
        return (
            <div className="practice-write-instructor-block d-flex align-items-center" key={item.id} onClick={() => changeInstructor(item)}>
                <div className="practice-write-instructor-avatar">
                    <img src={item.photo} alt=""/>
                </div>
                <div className="practice-write-instructor-info">
                    <div className="practice-write-instructor-name">{item.lastname} {item.name} {item.patronymic}</div>
                    <div className="practice-write-instructor-phone">{item.phoneFormat}</div>
                </div>
            </div>
        )
    });

    // смена режима отображения
    const changeMode = type => {
        setItems([]);

        // смена режима отображения
        setViewMode(type);

        // установка сегодняшней даты и обновление свободного времени
        setDate(new Date());
    }

    // алерт при отсутствии инструкторов
    if (props.instructor === false) {
        return (
            <React.Fragment>
                <div className="error-alert pt-4">
                    <div className="bg-white text-center ml-4 mr-4 pt-4 pb-4">
                        <img src="https://client.pravamobil.ru/dist/img/alert-error.png" />
                        <div className="text-bold mt-4">Инструкторы не назначены!<br />Обратитесь в свою автошколу!</div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    if (pageLoaded) {
        return (
            <div className="loader">
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
            </div>
        )
    }

    // рендер страницы
    return (
        <React.Fragment>
            <div className={'content' + (!isMobile ? ' pt-3' : ' mobile')}>
                <div className={(isMobile ? 'practice-write-page ' : 'container-fluid ') + 'position-relative ' + (changeClass === false ? '' : 'open-instructor-list')}>
                    <div className="practice-write-instructor-block-wrapper d-flex align-items-center">
                        <div className="practice-write-instructor-block d-flex align-items-center position-relative" onClick={showInstructor}>
                            <div className="practice-write-instructor-avatar">
                                <img src={instructor.photo} alt=""/>
                            </div>
                            <div className="practice-write-instructor-info">
                                <div className="practice-write-instructor-name">{instructor.lastname} {instructor.name} {instructor.patronymic}</div>
                                <div className="practice-write-instructor-phone">
                                    <a href={'tel:' + instructor.phone}>{instructor.phoneFormat}</a>
                                </div>
                            </div>
                               {instructors.length > 1 ? (
                                   <div className="position-absolute" style={{right: '17px', color: '#a3b8ce'}}><i className="fas fa-cog"></i></div>
                               ) : ''}
                        </div>
                    </div>
                    {instructors.length > 1 ? (
                        <div className={'practice-write-instructors-block position-absolute ' + (changeClass === false ? '' : 'show')}>
                            {instructorsElement}
                        </div>
                    ) : ''}
                    <div className="practice-count">Доступно занятий: {props.client.practice}</div>
                    {isMobile ? (
                        <div className="practice-write-block">
                            <div className="practice-write-header d-flex align-items-center justify-content-between p-3">
                                {viewMode === 'standart' ? (
                                    <DatePicker
                                        selected={selectDate}
                                        onChange={date => setDate(date)}
                                        minDate={new Date()}
                                        maxDate={addDays(new Date(), props.client.timeWrite)}
                                        customInput={<DatePickerButton/>}
                                        locale={ru}
                                        includeDates={includeDates}
                                    />
                                ) : (
                                    <div className="d-block" style={{width:'24px', height:'26px'}}></div>
                                )}
                                <div className="practice-write-day-title">{viewMode === 'standart' ? selectDateTitle : 'Свободное время'}</div>
                                <div className="practice-write-mode">
                                    <div className="btn-group">
                                        <div data-toggle="dropdown" aria-expanded="false">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M19.001 4.6738C19.001 6.7024 17.3558 8.3476 15.3272 8.3476C13.2986 8.3476 11.6543 6.7024 11.6543 4.6738C11.6543 2.6452 13.2986 1 15.3272 1C17.3558 1 19.001 2.6452 19.001 4.6738Z" stroke="#899CB1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path fillRule="evenodd" clipRule="evenodd" d="M8.3467 4.6738C8.3467 6.7024 6.7024 8.3476 4.6729 8.3476C2.6452 8.3476 1 6.7024 1 4.6738C1 2.6452 2.6452 1 4.6729 1C6.7024 1 8.3467 2.6452 8.3467 4.6738Z" stroke="#899CB1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path fillRule="evenodd" clipRule="evenodd" d="M19.001 15.2619C19.001 17.2905 17.3558 18.9348 15.3272 18.9348C13.2986 18.9348 11.6543 17.2905 11.6543 15.2619C11.6543 13.2333 13.2986 11.5881 15.3272 11.5881C17.3558 11.5881 19.001 13.2333 19.001 15.2619Z" stroke="#899CB1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path fillRule="evenodd" clipRule="evenodd" d="M8.3467 15.2619C8.3467 17.2905 6.7024 18.9348 4.6729 18.9348C2.6452 18.9348 1 17.2905 1 15.2619C1 13.2333 2.6452 11.5881 4.6729 11.5881C6.7024 11.5881 8.3467 13.2333 8.3467 15.2619Z" stroke="#899CB1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </div>
                                        <div className="dropdown-menu dropdown-menu-right">
                                            <div className="dropdown-item" onClick={() => changeMode('standart')}>По дням</div>
                                            <div className="dropdown-item" onClick={() => changeMode('full')}>Полностью</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="practice-write-body">
                                {viewMode === 'standart' ? (
                                    <div className="practice-write-times custom-scroll">
                                        <div className={'practice-write-body-title' + (itemsElements.length === 0 ? (' d-none') : (''))}>Доступное время</div>
                                        {isLoaded === false ? (
                                            <div className="practice-write-times custom-scroll">
                                                {itemsElements.length !== 0 ? (
                                                    itemsElements
                                                ) : (
                                                    <div className="text-center text-bold mt-3">Доступного времени нет!</div>
                                                )}
                                            </div>
                                        ) : (
                                            <div className="loader">
                                                <div className="circle"></div>
                                                <div className="circle"></div>
                                                <div className="circle"></div>
                                                <div className="circle"></div>
                                                <div className="circle"></div>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div className="practice-write-full" style={{height:'100%',overflowY:'auto'}}>
                                        {isLoaded === false ? (
                                            <div className="practice-write-times custom-scroll">
                                                {itemsFullElements.length !== 0 ? (
                                                    itemsFullElements
                                                ) : (
                                                    <div className="text-center text-bold mt-3">Доступного времени нет!</div>
                                                )}
                                            </div>
                                        ) : (
                                            <div className="loader">
                                                <div className="circle"></div>
                                                <div className="circle"></div>
                                                <div className="circle"></div>
                                                <div className="circle"></div>
                                                <div className="circle"></div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className="practice-schedule">
                            {isLoaded === false ? (
                                <div className="tabs">
                                    <div className="tabs-header">{tabsLink}</div>
                                    <div className="tabs-body">
                                        {tabsBlock.length !== 0 ? (
                                            tabsBlock
                                        ) : (
                                            <div className="text-center text-bold mt-3">Расписание не установлено!</div>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div className="loader">
                                    <div className="circle"></div>
                                    <div className="circle"></div>
                                    <div className="circle"></div>
                                    <div className="circle"></div>
                                    <div className="circle"></div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <Modal visible={isModal} content={isModalContent}/>
            <Toaster/>
        </React.Fragment>
    )
}

export default Write;
